body {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

@font-face {
  font-family: neue-montreal-normal;
  src: url(../public/PPNeueMontreal-Book.otf);
}

ul {
  font-family: neue-montreal-normal;
  margin: 4rem 3rem .5rem 3rem;
  padding: 0;
}

li {
  list-style-type: none;
  margin-top: .5rem;
  transition: .2s ease-in-out all;
  cursor: pointer;
}

li:hover {
  opacity: .7;
}

body {
  background: linear-gradient(34deg, #000000, #222222);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 0s ease infinite;
  -moz-animation: AnimationName 0s ease infinite;
  animation: AnimationName 0s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}


.thumbnail-image{
  width: 50%;
}

.description-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  gap: 1rem;
}




.learn-more-button {
  background-color: black;
  border: .5px solid white;
  color: white;
  font-size: 17px;
  padding: .5rem;
  font-family: neue-montreal-thin;
  cursor: pointer;
  transition: .3s ease-in-out all;
}

.learn-more-button:hover {
  background-color: white;
  color: black;
  
}